/**
 * 对vue-router对象的封装，使可以方便的获得路由路径，父路由等信息。
 */
export default class RouterInfo {
  name = null
  title = null
  path = null
  params = {}
  constructor (router) {
    this.router = router
    if (router) {
      this.name = router.name
      this.title = router.meta.title
      this.params = router.params
    }
  }

  isValid () {
    return this.router && this.router.matched.length > 0
  }

  _createByRouteConfig (config) {
    const info = new RouterInfo()
    info.name = config.name
    info.title = config.meta.title
    info.path = config.path
    info.params = this.params
    return info
  }

  /**
   * @param routeName {String} 表明获取的路由从第一个到routeName这个
   * @return {*}
   */
  getRoutes (routeName) {
    if (!this.isValid()) return []
    const tmp = this.router.matched.filter(i => i.name === (routeName || this.router.name))
    if (tmp && tmp.length === 1) {
      let r = tmp[0]
      const routes = []
      while (r !== undefined) {
        routes.push(this._createByRouteConfig(r))
        r = r.parent
      }
      return routes.reverse()
    }
    return []
  }

  getUri () {
    let uri = this.path
    for (const key of Object.keys(this.params)) {
      uri = uri.replace(':' + key, this.params[key])
    }
    return uri
  }

  getParent () {
    if (this.isValid() && this.router.matched.length > 0) {
      const routeConfig = this.router.matched[this.router.matched.length - 1]
      if (routeConfig.parent) {
        return this._createByRouteConfig(routeConfig.parent)
      } else {
        return null
      }
    }
    return null
  }

  getRoot () {
    if (this.isValid() && this.router.matched.length > 0) {
      const routeConfig = this.router.matched[0]
      return this._createByRouteConfig(routeConfig)
    }
    return null
  }
}
