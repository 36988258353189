/*
  基于stomp-js封装的stomp工具类，参考：https://stomp-js.github.io
  支持全局的connectHeaders配置（通常在App.vue那里做这个配置）：
  import { configConnectHeaders } from ...
  configConnectHeaders({
    yourHeader: 'yourHeader'
  })

  使用：
  import Stomp from ...
  let stomp = new Stomp()
  stomp.connect(url, headers, success => {
    if (success) {
      // do subscribe
      stomp.subscribe('destination', message => {
      })
    }
  })
 */
import SockJS from 'sockjs-client'
import { Client } from '@stomp/stompjs'
import kit from '../utils/kit.js'
import env from '../env'

const globalConnectHeaders = {}

class Stomp {
  constructor (reconnect) {
    this.reconnect = reconnect === undefined ? true : reconnect
    this.client = null
    this.subscriptions = {} // 已经订阅的地址
  }

  /**
   * @param [headers] {Function | Object} 如果是function，被当作callback。
   * @param [callback] {Function}
   */
  connect (headers, callback) {
    const url = env.STOMP_URL
    if (typeof headers === 'function') {
      callback = headers
      headers = {}
    }
    headers = kit.obj.merge({}, globalConnectHeaders, headers)
    this.client = new Client({
      connectHeaders: headers,
      debug: function (str) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('------------------------ stomp debug ------------------------')
          console.log(str)
        }
      },
      reconnectDelay: this.reconnect ? 1000 * 10 : 0,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    })

    this.client.webSocketFactory = function () {
      return new SockJS(url)
    }

    this.client.onConnect = () => {
      callback && callback.call(this, true)
    }

    this.client.onStompError = frame => {
      callback && callback.call(this, false, frame)
    }

    this.client.activate()
  }

  close () {
    if (this.client != null) {
      this.client.deactivate()
      this.client = null
    }
  }

  send (destination, payload) {
    let params = destination
    if (typeof destination === 'string') {
      params = {
        destination: destination,
        body: payload
      }
    }
    this.client.publish(params)
  }

  /**
   * 订阅服务器消息。
   * @param destination
   * @param [callback] {Function} 得到message.body消息。
   * @param [responseType] {String} 默认json，将message.body转换成json对象再交给callback。
   */
  subscribe (destination, callback, responseType) {
    if (responseType === undefined) {
      responseType = 'json'
    }
    if (this.client != null) {
      this.subscriptions[destination] = this.client.subscribe(destination, function (message) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`subscribe: ${destination}`)
          console.log(message.body)
          console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
        }
        if (message.command === 'MESSAGE') {
          if (responseType === 'json') {
            callback && callback(JSON.parse(message.body))
          } else {
            callback && callback(message.body)
          }
        }
      })
    }
  }

  unSubscribe (destination) {
    const sub = this.subscriptions[destination]
    if (sub) {
      sub.unsubscribe()
    }
  }
}

export default Stomp
export const configConnectHeaders = function (config) {
  Object.assign(globalConnectHeaders, config)
}
