import defineApi from '../define-api'
import http from '../application-api'

export const addSupplierProduceItemToDelivery = defineApi((config, itemIdList) => {
  config.params = {
    itemIdList: itemIdList
  }
  http.post('/supplier_produce_item/delivery_ready/add', config)
})

export const listProduceItem = defineApi((config) => {
  http.get('/supplier_produce_item/delivery_ready/list', config)
})

export const delivery = defineApi((config, data) => {
  config.data = data
  http.post('/supplier_produce_item/delivery_ready/delivery', config)
})

export const uploadImageByAddItem = defineApi((config, imageBase64) => {
  config.params = {
    base64: imageBase64
  }
  http.post('/supplier_produce_item/add/upload/image', config)
})

export const uploadImage = defineApi((config, id, imageBase64) => {
  config.params = {
    id: id,
    base64: imageBase64
  }
  http.post('/supplier_produce_item/delivery_ready/upload_image', config)
})

export const removeFromDeliveryList = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/supplier_produce_item/delivery_ready/delete', config)
})

