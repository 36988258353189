import defineApi from '../define-api'
import http from '../application-api'

export const listShipmentByProduceItemId = defineApi((config, itemId) => {
  config.params = {
    produceItemId: itemId
  }
  http.get('/supplier_produce_item/shipment/list/by_produce_item', config)
})

export const listSupplierShipments = defineApi((config, params) => {
  config.params = params
  http.get('/supplier_produce_item/shipment/list/supplier_shipments', config)
})

export const deleteSupplierShipment = defineApi((config, trackNo) => {
  config.params = {
    trackNo: trackNo
  }
  http.post('/supplier_produce_item/shipment/delete/track_no', config)
})

export const updateSupplierShipment = defineApi((config, oldTrackNo, newTrackNo, status) => {
  config.params = {
    oldTrackNo: oldTrackNo,
    newTrackNo: newTrackNo,
    status: status
  }
  http.post('/supplier_produce_item/shipment/update/track_no', config)
})
