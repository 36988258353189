/**
 * 导航守卫做如下事情：
 * 1. 检查路由是否存在，不存在跳转到404页面。
 * 2. 将路由的根的名称更新到状态管理器中。
 * 3. 检查路由的fromParent属性，决定是否能打开该路由或进行上级跳转。
 * @author jiangxingshang
 * @date 2020/12/11
 */

import store, { pageTitleStore, SET_ACTIVE_MENU, SET_CURRENT_ROUTE_PATH } from '@/store'
import RouterInfo from '@/router/router-info'
import { ROUTE_HOME, ROUTE_404 } from '@/router/router-constants'

export default function before (router) {
  router.beforeEach((to, from, next) => {
    store.commit(SET_CURRENT_ROUTE_PATH, to.path)
    pageTitleStore.setTitle(to.meta.title)
    if (to.matched.length === 0) {
      store.commit(SET_ACTIVE_MENU, null)
      return next({ name: ROUTE_404, query: { from: to.path } })
    } else {
      // 从路径中找到home这个节点，它的下一个节点就是左侧菜单的路由节点
      const homeIndex = to.matched.findIndex(r => r.name === ROUTE_HOME)
      let menuItemIndex = -1
      if (homeIndex >= 0) {
        menuItemIndex = homeIndex + 1
      }
      if (menuItemIndex >= 0 && menuItemIndex < to.matched.length) {
        store.commit(SET_ACTIVE_MENU, to.matched[menuItemIndex].name)
      } else {
        store.commit(SET_ACTIVE_MENU, null)
      }
    }
    if (to.meta.fromParent === true) {
      const targetRouter = new RouterInfo(to)
      const parentRouter = targetRouter.getParent()
      if (parentRouter.name === from.name) {
        next()
      } else {
        next({ name: parentRouter.name, params: targetRouter.params })
      }
    } else {
      next()
    }
  })
  return router
}
