/*
  重构vue-cli 3的环境变量，任何以VUE_APP_开头的变量名都去掉这部分，如：
  VUE_APP_NAME=my web
  获取该环境变量的方式：
  import env from ...
  console.log(env.NAME)
 */
const env = {}
for (const key of Object.keys(process.env)) {
  let prop = key
  if (key.startsWith('VUE_APP_')) {
    prop = key.substr(8, key.length)
  }
  env[prop] = process.env[key]
}

export default env
