import defineApi from '../define-api'
import http from '../application-api'

export const updateEmailSetting = defineApi((config, data) => {
  config.data = data
  http.post('/shop/email_client/update', config)
})

export const getEmailSetting = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/shop/email_client/get_by_id', config)
})
