import kit from '@/utils/kit'

export const SUPPLIER_PRODUCE_ITEM_STATUS_NEW = 'new'
export const SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT = 'accept'
export const SUPPLIER_PRODUCE_ITEM_STATUS_REJECT = 'reject'
export const SUPPLIER_PRODUCE_ITEM_STATUS_DELIVERY = 'delivery'
export const SUPPLIER_PRODUCE_ITEM_STATUS_RETURN = 'return'
export const SUPPLIER_PRODUCE_ITEM_STATUS_DELETE = 'delete'

export const SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING = {
  [SUPPLIER_PRODUCE_ITEM_STATUS_NEW]: '新单',
  [SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT]: '已接单',
  [SUPPLIER_PRODUCE_ITEM_STATUS_REJECT]: '拒单',
  [SUPPLIER_PRODUCE_ITEM_STATUS_DELIVERY]: '已发货',
  [SUPPLIER_PRODUCE_ITEM_STATUS_RETURN]: '退货',
  [SUPPLIER_PRODUCE_ITEM_STATUS_DELETE]: '废弃'
}

export const SUPPLIER_PRODUCE_ITEM_STATUS_LIST = kit.obj.toArray(SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING)
